
import './App.css';
import axios from "axios";
import { useEffect } from "react";
function App() {

  useEffect(() => {
    var data={
      name:"moksh"
    }
    axios
    .post("https://samplenodeapp.destinoinfotech.com/api/order",data)
    .then((response) => { 
  console.log(response.data.result + " my api valueee");
    });

     
  }, []);
 

  return (
    <div className="App">
    HEYYY THIS IS TEST APPP
    </div>
  );
}

export default App;
